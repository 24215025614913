<template>
  <div class="socials-section box-shadow-effect" id="my-socials">
    <div class="container animation-observer-section">
      <div class="row">
        <div class="col-md-12">
          <h1 class="section-heading">MY SOCIALS</h1>
        </div>
      </div>
      <div class="row">
        <div v-for="(social, index) in socials" v-bind:key="index" class="col-md-3">
          <a :href="social.link" target="_blank">
            <img class="social-image" :src="require(`../../assets/images/socials/${social.imageName}`)" alt="{{social.imageName}}">
          </a>
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from '../Footer.vue';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'SocialsSection',
  props: {},
  components: {
    Footer
  },
  data() {
    return {
      socials: [
        {
          link: 'mailto:info@tms-as.de',
          imageName: 'email.png'
        },
        {
          link: 'https://www.linkedin.com/in/thomas-andraschunow-790041259/',
          imageName: 'linkedin.png'
        },
        {
          link: 'https://www.xing.com/profile/Thomas_Andraschunow/cv',
          imageName: 'xing.png'
        },
        {
          link: 'https://github.com/xULTiMATE1337',
          imageName: 'github.png'
        }
      ]
    }
  },
  methods: {}
}
</script>

<style scoped>
.animation-observer-section {
  visibility: hidden;
}
.socials-section {
  text-align: center;
  color: var(--eerle-black);
  background-color: var(--white);
}
.section-heading {
  margin-top: 12vh;
  margin-bottom: 12vh;
  text-decoration: underline var(--blue);
  text-underline-offset: 0.5vh;
  font-family: 'LeagueSpartan-Medium', sans-serif;
  font-size: 60px;
}
a {
  color: var(--black);
  text-decoration: none;
}
a:hover, .social-image:hover {
  color: var(--charcoal);
  transition: 1s;
  transform: scale(1.15);
}
.social-image {
  width: 100px;
  margin-bottom: 8vh;
}
</style>
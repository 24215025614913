<template>
  <div class="privacy-section box-shadow-effect" id="my-privacy">
    <div class="container fade-in-animation">
      <!-- privacy -->
      <div class="row">
        <div class="col-12">
          <div class="privacy-explanation">
            <h1 class="privacy-heading">Datenschutzerklärung</h1>
          </div>
          <div class="privacy-about">
            <p>Wir informieren Sie nachfolgend gemäß den gesetzlichen Vorgaben des Datenschutzrechts (insb. gemäß
              BDSG n.F. und der europäischen Datenschutz-Grundverordnung ‚DS-GVO‘) über die Art, den Umfang
              und Zweck der Verarbeitung personenbezogener Daten durch unser Unternehmen. Diese
              Datenschutzerklärung gilt auch für unsere Websites und Sozial-Media-Profile. Bezüglich der Definition von
              Begriffen wie etwa „personenbezogene Daten“ oder „Verarbeitung“ verweisen wir auf Art. 4 DS-GVO.</p>
            <h5><strong>Name und Kontaktdaten des / der Verantwortlichen</strong></h5>
            <p>Unser/e Verantwortliche/r (nachfolgend „Verantwortlicher“) i.S.d. Art. 4 Zif. 7 DS-GVO ist:</p>
            <img src="../../assets/images/imprint/personalData.png">
            <br>
            <img class="image-contact" src="../../assets/images/imprint/contact.png">
            <h5><strong>Datenarten, Zwecke der Verarbeitung und Kategorien betroffener Personen</strong></h5>
            <p>Nachfolgend informieren wir Sie über Art, Umfang und Zweck der Erhebung, Verarbeitung und Nutzung
              personenbezogener Daten.</p>
            <h5><strong>1. Arten der Daten, die wir verarbeiten</strong></h5>
            <p>Nutzungsdaten (Zugriffszeiten, besuchte Websites etc.), Kontaktdaten (Telefonnummer, E-Mail, Fax etc.),
              Kommunikationsdaten (IP-Adresse etc.),</p>
            <h5><strong>2. Zwecke der Verarbeitung nach Art. 13 Abs. 1 c) DS-GVO</strong></h5>
            <p>Websites mit Funktionen und Inhalten bereitstellen,</p>
            <h5><strong>3. Kategorien der betroffenen Personen nach Art. 13 Abs. 1 e) DS-GVO</strong></h5>
            <p>Besucher/Nutzer der Website,</p>
            <p>Die betroffenen Personen werden zusammenfassend als „Nutzer“ bezeichnet.</p>
          </div>
          <div class="privacy-rights">
            <h5><strong>Rechtsgrundlagen der Verarbeitung personenbezogener Daten</strong></h5>
            <p>Nachfolgend Informieren wir Sie über die Rechtsgrundlagen der Verarbeitung personenbezogener Daten:</p>
            <p>1. Wenn wir Ihre Einwilligung für die Verarbeitung personenbezogenen Daten eingeholt haben, ist Art. 6
              Abs. 1 S. 1 lit. a) DS-GVO Rechtsgrundlage.</p>
            <p>2. Ist die Verarbeitung zur Erfüllung eines Vertrags oder zur Durchführung vorvertraglicher Maßnahmen
              erforderlich, die auf Ihre Anfrage hin erfolgen, so ist Art. 6 Abs. 1 S. 1 lit. b) DS-GVO Rechtsgrundlage.</p>
            <p>3. Ist die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung erforderlich, der wir unterliegen (z.B.
              gesetzliche Aufbewahrungspflichten), so ist Art. 6 Abs. 1 S. 1 lit. c) DS-GVO Rechtsgrundlage.</p>
            <p>4. Ist die Verarbeitung erforderlich, um lebenswichtige Interessen der betroffenen Person oder einer
              anderen natürlichen Person zu schützen, so ist Art. 6 Abs. 1 S. 1 lit. d) DS-GVO Rechtsgrundlage.</p>
            <p>5. Ist die Verarbeitung zur Wahrung unserer oder der berechtigten Interessen eines Dritten erforderlich
              und überwiegen diesbezüglich Ihre Interessen oder Grundrechte und Grundfreiheiten nicht, so ist Art.
              6 Abs. 1 S. 1 lit. f) DS-GVO Rechtsgrundlage.
            </p>
          </div>
          <div class="privacy-third">
            <h5><strong>Weitergabe personenbezogener Daten an Dritte und Auftragsverarbeiter</strong></h5>
            <p>Ohne Ihre Einwilligung geben wir grundsätzlich keine Daten an Dritte weiter. Sollte dies doch der Fall sein,
              dann erfolgt die Weitergabe auf der Grundlage der zuvor genannten Rechtsgrundlagen z.B. bei der
              Weitergabe von Daten an Online-Paymentanbieter zur Vertragserfüllung oder aufgrund gerichtlicher
              Anordnung oder wegen einer gesetzlichen Verpflichtung zur Herausgabe der Daten zum Zwecke der
              Strafverfolgung, zur Gefahrenabwehr oder zur Durchsetzung der Rechte am geistigen Eigentum.
              Wir setzen zudem Auftragsverarbeiter (externe Dienstleister z.B. zum Webhosting unserer Websites und
              Datenbanken) zur Verarbeitung Ihrer Daten ein. Wenn im Rahmen einer Vereinbarung zur
              Auftragsverarbeitung an die Auftragsverarbeiter Daten weitergegeben werden, erfolgt dies immer nach Art.
              28 DS-GVO. Wir wählen dabei unsere Auftragsverarbeiter sorgfältig aus, kontrollieren diese regelmäßig
              und haben uns ein Weisungsrecht hinsichtlich der Daten einräumen lassen. Zudem müssen die
              Auftragsverarbeiter geeignete technische und organisatorische Maßnahmen getroffen haben und die
              Datenschutzvorschriften gem. BDSG n.F. und DS-GVO einhalten.
            </p>
          </div>
          <div class="privacy-third-country">
            <h5><strong>Datenübermittlung in Drittstaaten</strong></h5>
            <p>Durch die Verabschiedung der europäischen Datenschutz-Grundverordnung (DS-GVO) wurde eine
              einheitliche Grundlage für den Datenschutz in Europa geschaffen. Ihre Daten werden daher vorwiegend
              durch Unternehmen verarbeitet, für die DS-GVO Anwendung findet. Sollte doch die Verarbeitung durch
              Dienste Dritter außerhalb der Europäischen Union oder des Europäischen Wirtschaftsraums stattfinden, so
              müssen diese die besonderen Voraussetzungen der Art. 44 ff. DS-GVO erfüllen. Das bedeutet, die
              Verarbeitung erfolgt aufgrund besonderer Garantien, wie etwa die von der EU-Kommission offiziell
              anerkannte Feststellung eines der EU entsprechenden Datenschutzniveaus oder der Beachtung offiziell
              anerkannter spezieller vertraglicher Verpflichtungen, der so genannten „Standardvertragsklauseln“.
              Soweit wir aufgrund der Unwirksamkeit des sog. „Privacy Shields“, nach Art. 49 Abs. 1 S. 1 lit. a) DSGVO
              die ausdrückliche Einwilligung in die Datenübermittlung in die USA von Ihnen einholen, weisen wird
              diesbezüglich auf das Risiko eines geheimen Zugriffs durch US-Behörden und die Nutzung der Daten zu
              Überwachungszwecken, ggf. ohne Rechtsbehelfsmöglichkeiten für EU-Bürger, hin.</p>
          </div>
          <div class="privacy-deleting">
            <h5><strong>Löschung von Daten und Speicherdauer</strong></h5>
            <p>Sofern nicht in dieser Datenschutzerklärung ausdrücklich angegeben, werden Ihre personenbezogen Daten
              gelöscht oder gesperrt, sobald die zur Verarbeitung erteilte Einwilligung von Ihnen widerrufen wird oder der
              Zweck für die Speicherung entfällt bzw. die Daten für den Zweck nicht mehr erforderlich sind, es sei denn
              deren weitere Aufbewahrung ist zu Beweiszwecken erforderlich oder dem stehen gesetzliche
              Aufbewahrungspflichten entgegenstehen. Darunter fallen etwa handelsrechtliche Aufbewahrungspflichten
              von Geschäftsbriefen nach § 257 Abs. 1 HGB (6 Jahre) sowie steuerrechtliche Aufbewahrungspflichten
              nach § 147 Abs. 1 AO von Belegen (10 Jahre). Wenn die vorgeschriebene Aufbewahrungsfrist abläuft,
              erfolgt eine Sperrung oder Löschung Ihrer Daten, es sei denn die Speicherung ist weiterhin für einen
              Vertragsabschluss oder zur Vertragserfüllung erforderlich.</p>
          </div>
          <div class="privacy-no-profiling">
            <h5><strong>Bestehen einer automatisierten Entscheidungsfindung</strong></h5>
            <p>Wir setzen keine automatische Entscheidungsfindung oder ein Profiling ein.</p>
          </div>
          <div class="privacy-logs">
            <h5><strong>Bereitstellung unserer Website und Erstellung von Logfiles</strong></h5>
            <p>1. Wenn Sie unsere Webseite lediglich informatorisch nutzen (also keine Registrierung und auch keine
              anderweitige Übermittlung von Informationen), erheben wir nur die personenbezogenen Daten, die Ihr
              Browser an unseren Server übermittelt. Wenn Sie unsere Website betrachten möchten, erheben wir
              die folgenden Daten:</p>
            <p>IP-Adresse;</p>
            <p>ervice-Provider des Nutzers;</p>
            <p>Uhrzeit des Abrufs;</p>
            <p>Browsertyp;</p>
            <p>Browser-Version;</p>
            <p>Abrufs;</p>
            <p>Zeitzone;</p>
            <p>Zugriffsstatus/HTTP-Statuscode;</p>
            <p>Datenmenge;</p>
            <p>Websites, von denen die Anforderung kommt;</p>
            <p>Betriebssystem.</p>
            <p>Eine Speicherung dieser Daten zusammen mit anderen personenbezogenen Daten von Ihnen findet
              nicht statt.</p>
            <p>2. Diese Daten dienen dem Zweck der nutzerfreundlichen, funktionsfähigen und sicheren Auslieferung
              unserer Website an Sie mit Funktionen und Inhalten sowie deren Optimierung und statistischen
              Auswertung.</p>
            <p>3. Rechtsgrundlage hierfür ist unser in den obigen Zwecken auch liegendes berechtigtes Interesse an
              der Datenverarbeitung nach Art. 6 Abs. 1 S.1 lit. f) DS-GVO.</p>
            <p>4. Wir speichern aus Sicherheitsgründen diese Daten in Server-Logfiles für die Speicherdauer von 14
              Tagen. Nach Ablauf dieser Frist werden diese automatisch gelöscht, es sei denn wir benötigen deren
              Aufbewahrung zu Beweiszwecken bei Angriffen auf die Serverinfrastruktur oder anderen
              Rechtsverletzungen.</p>
          </div>
          <div class="privacy-contact">
            <h5><strong>Kontaktaufnahme per Kontaktformular / E-Mail / Fax / Post</strong></h5>
            <p>1. Bei der Kontaktaufnahme mit uns per Kontaktformular, Fax, Post oder E-Mail werden Ihre Angaben
              zum Zwecke der Abwicklung der Kontaktanfrage verarbeitet.</p>
            <p>2. Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen einer Einwilligung von Ihnen Art. 6
              Abs. 1 S. 1 lit. a) DS-GVO. Rechtsgrundlage für die Verarbeitung der Daten, die im Zuge einer
              Kontaktanfrage oder E-Mail, eines Briefes oder Faxes übermittelt werden, ist Art. 6 Abs. 1 S. 1 lit. f)
              DS-GVO. Der Verantwortliche hat ein berechtigtes Interesse an der Verarbeitung und Speicherung der
              Daten, um Anfragen der Nutzer beantworten zu können, zur Beweissicherung aus Haftungsgründen
              und um ggf. seiner gesetzlichen Aufbewahrungspflichten bei Geschäftsbriefen nachkommen zu
              können. Zielt der Kontakt auf den Abschluss eines Vertrages ab, so ist zusätzliche Rechtsgrundlage
              für die Verarbeitung Art. 6 Abs. 1 S. 1 lit. b) DS-GVO.</p>
            <p>3. Wir können Ihre Angaben und Kontaktanfrage in unserem Customer-Relationship-Management
              System ("CRM System") oder einem vergleichbaren System speichern.</p>
            <p>4. Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr
              erforderlich sind. Für die personenbezogenen Daten aus der Eingabemaske des Kontaktformulars und
              diejenigen, die per E-Mail übersandt wurden, ist dies dann der Fall, wenn die jeweilige Konversation
              mit Ihnen beendet ist. Beendet ist die Konversation dann, wenn sich aus den Umständen entnehmen
              lässt, dass der betroffene Sachverhalt abschließend geklärt ist. Anfragen von Nutzern, die über einen
              Account bzw. Vertrag mit uns verfügen, speichern wir bis zum Ablauf von zwei Jahren nach
              Vertragsbeendigung. Im Fall von gesetzlichen Archivierungspflichten erfolgt die Löschung nach deren
              Ablauf: Ende handelsrechtlicher (6 Jahre) und steuerrechtlicher (10 Jahre) Aufbewahrungspflicht.</p>
            <p>5. Sie haben jederzeit die Möglichkeit, die Einwilligung nach Art. 6 Abs. 1 S. 1 lit. a) DS-GVO zur
              Verarbeitung der personenbezogenen Daten zu widerrufen. Nehmen Sie per E-Mail Kontakt mit uns
              auf, so können Sie der Speicherung der personenbezogenen Daten jederzeit widersprechen.</p>
          </div>
          <div class="privacy-social">
            <h5><strong>Präsenz in sozialen Medien / Externe Links</strong></h5>
            <p>1. Wir unterhalten in sozialen Medien Profile bzw. Fanpages. Bei der Nutzung und dem Aufruf unseres
              Profils im jeweiligen Netzwerk durch Sie gelten die jeweiligen Datenschutzhinweise und
              Nutzungsbedingungen des jeweiligen Netzwerks.</p>
            <p><strong>2. Datenkategorien und Beschreibung der Datenverarbeitung: </strong></p>
            <p>Nutzungsdaten, Kontaktdaten,
              Inhaltsdaten, Bestandsdaten. Ferner werden die Daten der Nutzer innerhalb sozialer Netzwerke im
              Regelfall für Marktforschungs- und Werbezwecke verarbeitet. So können z.B. anhand des
              Nutzungsverhaltens und sich daraus ergebender Interessen der Nutzer Nutzungsprofile erstellt
              werden. Die Nutzungsprofile können wiederum verwendet werden, um z.B. Werbeanzeigen innerhalb
              und außerhalb der Netzwerke zu schalten, die mutmaßlich den Interessen der Nutzer entsprechen. Zu
              diesen Zwecken werden im Regelfall Cookies auf den Rechnern der Nutzer gespeichert, in denen das
              Nutzungsverhalten und die Interessen der Nutzer gespeichert werden. Ferner können in den
              Nutzungsprofilen auch Daten unabhängig der von den Nutzern verwendeten Geräte gespeichert
              werden (insbesondere, wenn die Nutzer Mitglieder der jeweiligen Plattformen sind und bei diesen
              eingeloggt sind). Für eine detaillierte Darstellung der jeweiligen Verarbeitungsformen und der
              Widerspruchsmöglichkeiten (Opt-Out) verweisen wir auf die Datenschutzerklärungen und Angaben
              der Betreiber der jeweiligen Netzwerke. Auch im Fall von Auskunftsanfragen und der Geltendmachung
              von Betroffenenrechten weisen wir darauf hin, dass diese am effektivsten bei den Anbietern geltend
              gemacht werden können. Nur die Anbieter haben jeweils Zugriff auf die Daten der Nutzer und können
              direkt entsprechende Maßnahmen ergreifen und Auskünfte geben. Sollten Sie dennoch Hilfe
              benötigen, dann können Sie sich an uns wenden.
            </p>
            <p><strong>3. Zweck der Verarbeitung:</strong></p>
            <p>Kommunikation mit den auf den sozialen Netzwerken angeschlossenen
              und registrierten Nutzern; Information und Werbung für unsere Produkte, Angebote und
              Dienstleistungen; Außerdarstellung und Imagepflege; Auswertung und Analyse der Nutzer und Inhalte
              unserer Präsenzen in den sozialen Medien.</p>
            <p><strong>4. Rechtsgrundlagen:</strong></p>
            <p>Die Rechtsgrundlage für die Verarbeitung der personenbezogenen Daten ist
              unser in den obigen Zwecken liegendes berechtigtes Interesse gemäß Art. 6 Abs. 1 S. 1 lit. f)
              DS-GVO. Soweit Sie uns bzw. dem Verantwortlichen des sozialen Netzwerks eine Einwilligung in die
              Verarbeitung Ihrer personenbezogenen Daten erteilt haben, ist Rechtsgrundlage Art. 6 Abs. 1 S. 1 lit.
              a) i.V.m. Art. 7 DS-GVO.</p>
            <p><strong>5. Datenübermittlung/Empfängerkategorie:</strong></p>
            <p> Soziales Netzwerk.</p>
            <p>6. Die Datenschutzhinweise, Auskunftsmöglichkeiten und Widerspruchmöglichkeiten (Opt-Out) der
              jeweiligen Netzwerke / Diensteanbieter finden Sie hier:</p>
            <p>XING: XING AG, Dammtorstraße 29-32, 20354 Hamburg, Deutschland -
              Datenschutzerklärung / Opt-Out: https://privacy.xing.com/de/datenschutzerklaerung.</p>
            <p>LinkedIn: LinkedIn Ireland Unlimited Company, Wilton Place, Dublin 2, Irland –
              Datenschutzerklärung: https://www.linkedin.com/legal/privacy-policy</p>
            <p>GitHub, Inc.: GitHub, Inc., 88 Colin P. Kelly Jr. St., San Francisco, CA 94107,
              United States – Datenschutzerklärung: https://docs.github.com/en/site-policy/privacy-policies/github-privacy-statement</p>
            <p>Take2-Design: Take2-Design GmbH & Co. KG: Goethestrasse 38-40, 83024 Rosenheim / Germany -
              Datenschutzerklärung: https://www.take2-design.de/datenschutz.html</p>
            <p>tuomi: Tuomi S.A., CEO Arja Roos, Fausermillen 7, L-6689 Mertert -
              Datenschutzerklärung: https://www.tuomi.de/home/datenschutz/</p>
          </div>
          <div class="privacy-security">
            <h5><strong>Datensicherheit</strong></h5>
            <p>Um alle personenbezogen Daten, die an uns übermittelt werden, zu schützen und um sicherzustellen, dass
              die Datenschutzvorschriften von uns, aber auch unseren externen Dienstleistern eingehalten werden,
              haben wir geeignete technische und organisatorische Sicherheitsmaßnahmen getroffen. Deshalb werden
              unter anderem alle Daten zwischen Ihrem Browser und unserem Server über eine sichere SSL-Verbindung
              verschlüsselt übertragen.</p>
            <p><strong>Stand: 25.12.2022 </strong></p>
          </div>
          <div class="privacy-source">
            <p>Quelle: <a href="https://www.juraforum.de/">https://www.juraforum.de/</a></p>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from '../Footer.vue';

export default {
  name: 'PrivacySection',
  props: {},
  components: {
    Footer
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped>
.privacy-section {
  background-color: var(--white);
  color: var(--eerle-black);
}
.privacy-heading {
  text-align: center;
  margin-top: 8vh;
  margin-bottom: 8vh;
  text-decoration: underline var(--blue);
  text-underline-offset: 0.5vh;
  font-family: 'LeagueSpartan-Medium', sans-serif;
  font-size: 60px;
}
h1 {
  margin-top: 8vh;
}
a {
  text-decoration: none;
  color: var(--eerle-black);
}
.image-contact {
  margin-bottom: 4vh;
}
</style>
<template>
  <MyPortfolio />
  <SkillsSection />
  <ProjectSection />
  <SocialsSection />
</template>

<script>
import { startObserver } from '@/javascript/scroll';

import MyPortfolio from '../components/MyPortfolio.vue';
import SkillsSection from '../components/skills/SkillsSection.vue';
import ProjectSection from '../components/projects/ProjectSection.vue';
import SocialsSection from '../components/socials/SocialsSection.vue';

export default {
  name: 'MainView',
  components: {
    MyPortfolio,
    SkillsSection,
    ProjectSection,
    SocialsSection
  },
  data() {
    return {
      test: 0
    }
  },
  methods: {},
  mounted() {
    startObserver();
    document.title = 'tms-as.de | MyPortfolio';
  }
}
</script>

<style scoped>

</style>
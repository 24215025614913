<template>
  <div class="project" :class="{ lightBackgroundDarkFont, 'box-shadow-effect': this.lightBackgroundDarkFont }">
    <div class="container animation-observer-section">
        <div class="row" v-if="!lightBackgroundDarkFont">
          <div class="col-lg-8">
            <h1>{{ heading }}</h1>
            <h5>{{ text }}</h5>
            <div v-for="(language, index) in languages"
                 v-bind:key="index" class="project-language">
              {{ language }}
            </div>
          </div>
          <div class="col-lg-4 d-flex align-items-center justify-content-center">
            <a :href="projectUrl" target="_blank">
              <img :src="require(`../../assets/images/projects/${imageName}`)" :id="imageId">
            </a>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-lg-4 d-flex align-items-center justify-content-center">
            <a :href="projectUrl" target="_blank">
              <img :src="require(`../../assets/images/projects/${imageName}`)" :id="imageId">
            </a>
          </div>
          <div class="col-lg-8 order-first order-lg-last">
            <h1>{{ heading }}</h1>
            <h5>{{ text }}</h5>
            <div v-for="(language, index) in languages"
                 v-bind:key="index" class="project-language">
              {{ language }}
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Project',
  props: {
    heading: String,
    text: String,
    languages: Array,
    imageName: String,
    projectUrl: String,
    imageId: String,
    lightBackgroundDarkFont: Boolean
  },
  components: {},
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped>
.animation-observer-section {
  visibility: hidden;
}
.project {
  padding-top: 12vh;
  padding-bottom: 12vh;
}
h1 {
  font-family: 'LeagueSpartan-Medium', sans-serif!important;
  font-size: 55px;
}
h5 {
  font-family: 'LeagueSpartan-Light', sans-serif!important;
  margin-bottom: 2vh;
}
.lightBackgroundDarkFont {
  background-color: var(--white);
  color: var(--eerle-black);
}
.project-language {
  margin-top: 2vh;
  margin-right: 2.5vh;
  border: 0.30vh solid var(--blue);
  padding: 0.25vh 2vh 0.25vh 2vh;
  border-radius: 5px;
  float: left;
}
img {
  width: 200px;
  transition: transform 1s;
}
img:hover {
  transform: scale(1.15) rotate(12deg);
}
@media screen and (max-width: 990px) {
  img {
    margin-top: 12vh;
  }
}
#adventcalender {
  width: 250px;
}
#take2egg {
  width: 190px;
}
#beaconemulator {
  width: 125px;
}
</style>
<template>
  <div class="imprint-section box-shadow-effect" id="my-imprint">
    <div class="container fade-in-animation">
      <div class="row">
        <div class="col-md-12">
          <h1 class="imprint-heading">Impressum</h1>
          <div class="imprint-personal-data">
            <h2>Angaben gemäß § 5 TMG</h2>
            <img src="../../assets/images/imprint/personalData.png">
          </div>
          <div class="imprint-contact">
            <h2>Kontakt</h2>
            <img src="../../assets/images/imprint/contact.png">
          </div>
          <div class="imprint-source">
            <p>Quelle: <a href="https://www.e-recht24.de/">https://www.e-recht24.de/</a></p>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from '../Footer.vue';

export default {
  name: 'ImprintSection',
  props: {},
  components: {
    Footer
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped>
.imprint-section {
  background-color: var(--white);
  color: var(--eerle-black);
  min-height: 100vh;
}
.imprint-heading {
  text-align: center;
  margin-top: 8vh;
  margin-bottom: 8vh;
  text-decoration: underline var(--blue);
  text-underline-offset: 0.5vh;
  font-family: 'LeagueSpartan-Medium', sans-serif;
  font-size: 60px;
}
h1 {
  margin-top: 8vh;
}
a {
  text-decoration: none;
  color: var(--eerle-black);
}
.imprint-personal-data, .imprint-contact {
  margin-bottom: 4vh;
}
footer {
  position: fixed;
  margin-left: -8px;
  padding-bottom: 2.2vh!important;
}
</style>
<template>
  <div class="my-portfolio">
    <div class="container">
      <h1>Hello, my name is <span class="my-name">Thomas</span>.</h1>
      <h2>I'm a full stack developer, working with <span class="laravel-red">laravel</span> and <span class="vue-green">vue</span>.</h2>
      <div class="navigation-buttons">
        <a class="navigation-button" v-for="(button, index) in navigationButtons"
           :key="index"
           :href="button.id">{{ button.text }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyPortfolio',
  props: {},
  components: {},
  data() {
    return {
      navigationButtons: [
        {
          text: 'MY SKILLS',
          id: '#my-skills'
        },
        {
          text: 'MY PROJECTS',
          id: '#my-projects'
        },
        {
          text: 'MY SOCIALS',
          id: '#my-socials'
        }
      ],
    }
  },
  methods: {}
}
</script>

<style scoped>
.my-portfolio {
  min-height: 101vh;
  background: var(--eerle-black);
  color: var(--white);
}
.my-name {
  color: var(--blue);
}
.container {
  padding-top: 35vh;
  animation: fade-in 2s ease;
}
.navigation-button {
  color: var(--blue);
  background-color: transparent;
  border: 3px solid var(--blue);
  padding: 16px 12px 12px;
  text-decoration: none;
  margin-right: 3vh;
}
.navigation-button:hover {
  color: var(--charcoal);
  border: 3px solid var(--charcoal);
  transition: 0.5s;
}
.navigation-buttons {
  animation: bounce 2s ease;
  margin-top: 4vh;
}
@media (max-width: 410px) {
  .navigation-button {
    white-space: nowrap;
    display: table;
    margin-top: 2vh;
  }
  .my-portfolio {
    padding-bottom: 14vh;
  }
}
.laravel-red {
  color: var(--laravel-red);
}
.vue-green {
  color: var(--vue-green);
}
h1 {
  font-family: 'LeagueSpartan-Medium', sans-serif;
  font-size: 60px;
}
h2 {
  font-family: 'LeagueSpartan-Light', sans-serif;
  font-size: 35px;
}
</style>
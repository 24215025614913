<template>
  <div class="skill-card">
    <div class="skill-card-image">
      <img :src="require(`../../assets/images/skills/${imageName}`)">
    </div>
    <div class="skill-card-content">
      <h2 class="skill-card-heading">{{ skillCardHeading }}</h2>
      <p class="skill-card-skill" v-for="(skill, index) in skillCardSkills" v-bind:key="index">
        {{ skill }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SkillCard',
  props: {
    skillCardHeading: String,
    skillCardSkills: Array,
    imageName: String
  },
  components: {},
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped>
.skill-card {
  color: var(--white);
  background-color: var(--eerle-black);
  text-align: center;
  margin-bottom: 14vh;
  border: 2px solid transparent;
  border-radius: 8px;
  box-shadow: 0px 20px 40px -20px var(--eerle-black);
  border-top: 5px solid #1b9aaa;
  margin-left: 1rem;
  margin-right: 1rem;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}
.skill-card:hover {
  box-shadow: 0px 30px 40px -15px var(--eerle-black);
  transition: 0.1s;
  transform: scale(1.025);
}
img {
  width: 85px;
  color: var(--white);
  margin-top: 2vh;
  margin-bottom: 2vh;
}
#image-tools.png {
  margin-bottom: 5vh;
}
.skill-card-heading {
  margin-bottom: 2vh;
  font-family: 'LeagueSpartan-Medium', sans-serif;
  font-size: 40px;
}
.skill-card-skill {
  font-family: 'LeagueSpartan-Thin', sans-serif;
  font-size: 30px;
}
</style>
export function startObserver() {
    let animationScrollObserver = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add('fade-in-animation');
                entry.target.style.visibility = 'visible';
            }
        })
    })

    const sections = document.querySelectorAll('.animation-observer-section');

    sections.forEach(section => {
        animationScrollObserver.observe(section);
    })
}
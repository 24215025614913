<template>
  <PrivacySection />
</template>

<script>
import PrivacySection from '../components/privacy/PrivacySection';

export default {
  name: 'ImprintView',
  components: {
    PrivacySection
  },
  mounted() {
    document.title = 'tms-as.de | Datenschutzerklärung';
  }
}
</script>

<style scoped>

</style>
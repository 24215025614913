<template>
  <router-view/>
</template>

<script>
import './css/main.css';
import './css/animations.css';
import './javascript/scroll.js';

export default {
  name: 'App'
}
</script>

<style>

</style>

<template>
  <ImprintSection />
</template>

<script>
import ImprintSection from '../components/imprint/ImprintSection';

export default {
  name: 'ImprintView',
  components: {
    ImprintSection
  },
  mounted() {
    document.title = 'tms-as.de | Impressum';
  }
}
</script>

<style scoped>

</style>
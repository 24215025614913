<template>
  <footer>
    <p>2018 - {{ currentYear }} <span>|</span>
      <router-link v-on:click="scrollToTop" to="/"> tms-as.de </router-link>
        <span>|</span>
      <router-link v-on:click="scrollToTop" to="/imprint"> Imprint </router-link>
        <span>|</span>
      <router-link v-on:click="scrollToTop" to="/privacy"> Privacy </router-link>
    </p>
  </footer>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Footer',
  props: {},
  components: {},
  data() {
    return {
      currentYear: null
    }
  },
  mounted() {
    this.setCurrentYear()
  },
  methods: {
    setCurrentYear() {
      this.currentYear = new Date().getFullYear();
    },
    scrollToTop() {
      window.scrollTo(0,0);
    }
  }
}
</script>

<style scoped>
footer {
  left: 0;
  bottom: 0;
  width: 100%;
  padding-top: 4vh;
  padding-bottom: 0.1vh;
  margin-bottom: -20px!important;
  background-color: transparent;
  text-align: center;
  font-family: 'LeagueSpartan-Light',serif!important;
}
p {
  font-size: 18px!important;
}
.animation-observer-section {
  visibility: hidden;
}
span {
  color: var(--blue);
}
a {
  text-decoration: none;
  color: var(--eerle-black);
}
a:hover {
  transition: 1s;
  color: var(--blue);
}
</style>
import { createRouter, createWebHistory } from 'vue-router'
import MainView from '../views/MainView.vue'
import ImprintView from '../views/ImprintView';
import PrivacyView from '../views/PrivacyView';

const routes = [
  {
    path: '/',
    name: 'main',
    component: MainView
  },
  {
    path: '/imprint',
    name: 'imprint',
    component: ImprintView
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: PrivacyView
  }
]

const router = createRouter({
  history: createWebHistory(),
  mode: 'history',
  routes
})

export default router

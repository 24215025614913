<template>
  <div class="skills-section box-shadow-effect" id="my-skills">
    <div class="container animation-observer-section">
      <div class="row">
        <div class="col-md-12">
          <h1 class="section-heading">MY SKILLS</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4" v-for="(skill, index) in skills" :key="index">
          <SkillCard :skill-card-heading="skill.heading"
          :skill-card-skills="skill.skills"
          :imageName="skill.imageName">
          </SkillCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SkillCard from './SkillCard.vue';

export default {
  name: 'SkillsSection',
  props: {},
  components: {
    SkillCard
  },
  data() {
    return {
      skills: [
        {
          heading: 'Frontend',
          skills: ['HTML', 'CSS', 'Bootstrap', 'JavaScript', 'Vue.js', 'jQuery'],
          imageName: 'frontend.png'
        },
        {
          heading: 'Backend',
          skills: ['PHP', 'Laravel', 'phpMyAdmin', 'Lumen', 'MySQL'],
          imageName: 'backend.png'
        },
        {
          heading: 'Tools',
          skills: ['PhpStorm', 'XAMPP', 'MobaXterm', 'Git', 'GitHub', 'Postman'],
          imageName: 'tools.png'
        }
      ]
    }
  },
  methods: {}
}
</script>

<style scoped>
.skills-section {
  text-align: center;
  background-color: var(--white);
}
.section-heading {
  margin-top: 8vh;
  margin-bottom: 8vh;
  text-decoration: underline var(--blue);
  text-underline-offset: 0.5vh;
  font-family: 'LeagueSpartan-Medium', sans-serif;
  font-size: 60px;
}
.animation-observer-section {
  visibility: hidden;
}
</style>
<template>
  <div class="projects-section" id="my-projects">
    <div class="container animation-observer-section">
      <div class="row">
        <div class="col-md-12">
          <h1 class="section-heading">MY PROJECTS</h1>
        </div>
      </div>
    </div>
      <Project v-for="(project, index) in projects" v-bind:key="index"
               :imageId="project.imageId"
               :heading="project.heading"
               :text="project.text"
               :languages="project.languages"
               :imageName="project.imageName"
               :projectUrl="project.projectLink"
               :lightBackgroundDarkFont="project.lightBackgroundDarkFont" />
  </div>
</template>

<script>
import Project from './Project.vue';

export default {
  name: 'ProjectSection',
  props: {},
  components: {
    Project
  },
  data() {
    return {
      projects: [
        {
          lightBackgroundDarkFont: false,
          imageId: 'adventcalender',
          heading: 'Advent Calendar',
          text: 'This is a virtual advent calendar for customers of the DetailSchoen online shop. Various vouchers ' +
              'for the shop are hidden behind the 24 doors, such as discounts on orders and free shipping for ' +
              'orders over a certain value.',
          imageName: 'adventcalender.png',
          languages: [
            'Laravel',
            'Vue.js',
            'CSS'
          ],
          projectLink: 'https://adventskalender.detailschoen.de/'
        },
        {
          lightBackgroundDarkFont: true,
          imageId: 'take2egg',
          heading: 'Take2Ei',
          text: 'Take2Ei is an egg timer that calculates the perfect time. It is a web-based application that ' +
              'is only suitable for mobile devices. You choose the egg size or enter it manually, then you enter ' +
              'the stored temperature, the altitude metres above sea level and your desired egg hardness. Then ' +
              'you get a perfect timer for your egg. By the way, this was my first project.',
          imageName: 'take2egg.png',
          languages: [
            'HTML',
            'CSS',
            'JavaScript',
            'jQuery'
          ],
          projectLink: 'https://www.take2-design.de/Eieruhr-99.html'
        },
        {
          lightBackgroundDarkFont: false,
          imageId: 'beaconemulator',
          heading: 'Beacon Emulator App',
          text: 'My first and only Android app was a beacon emulator. This app was ' +
              'developed to emulate a beacon and send certain URLs with content to ' +
              'another software used on tablets. These tablets processed the URL and displayed the content.',
          imageName: 'beaconemulator.png',
          languages: [
            'Java'
          ],
          projectLink: 'https://tuomi.eu/home/showcase/projekte/Brocken.php/'
        },
        {
          lightBackgroundDarkFont: true,
          imageId: 'vote4food',
          heading: 'Vote4Food',
          text: 'This project was based on the daily question "What and where to eat today?" Vote4Food is a ' +
              'voting tool in which you create a survey indicating what and where you eat out. After you have ' +
              'entered all the options, you can create the poll and share the link. You can also limit the poll ' +
              'for a certain time. After the survey, the question is answered. ',
          imageName: 'vote4food.png',
          languages: [
            'Laravel',
            'Vue.js',
            'CSS'
          ],
          projectLink: 'https://vote4food.superkek.de/'
        },
        {
          lightBackgroundDarkFont: false,
          imageId: 'portfolio',
          heading: 'My Portfolio',
          text: 'My latest project is this portfolio. I hope you enjoy the projects and my portfolio!',
          imageName: 'portfolio.png',
          languages: [
            'Vue.js',
            'CSS'
          ],
          projectLink: 'https://tms-as.de/'
        },
      ]
    }
  },
  methods: {}
}
</script>

<style scoped>
.animation-observer-section {
  visibility: hidden;
}
.projects-section {
  background-color: var(--eerle-black);
  color: var(--white);
}
.section-heading {
  text-align: center;
  margin-top: 8vh;
  text-decoration: underline var(--blue);
  text-underline-offset: 0.5vh;
  font-family: 'LeagueSpartan-Medium', sans-serif;
  font-size: 60px;
}
</style>